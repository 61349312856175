<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              ref="dxGridRef"
              title="System Code"
              canSelect="none"
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadSystemCode()"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  :data-field="item.field"
                  :caption="item.caption"
                  v-for="(item, index) in fields"
                  :key="index"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      hide-footer
      v-model="modalShow"
      centered
      @hide="closeModal()"
      size="lg"
      no-close-on-backdrop
    >
      <validation-observer ref="statusRef" #default="{ invalid }">
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="Name"
              vid="v-contract_type"
              rules="required"
            >
              <b-form-group label-for="v-name">
                <label for="">
                  Name
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  maxlength="60"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  v-model="formData.name"
                  name="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Code" label-for="v-code">
              <v-select
                :options="options"
                v-model="formData.code"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Description" label-form="v-description">
              <b-form-textarea
                rows="4"
                v-model="formData.description"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <b-button size="sm" variant="primary" @click="onAddSelection()">
              Add
            </b-button>
          </b-col>
        </b-row>

        <label for="">
          Selections
          <span class="text-danger">*</span>
        </label>
        <b-row v-for="(item, index) in selectionItems" :key="index">
          <b-col>
            <b-form-group label-for="v-selection">
              <validation-provider
                #default="{ errors }"
                name="Text & Value"
                vid="v-selections"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend is-text label-for="v-text">
                    Text
                  </b-input-group-prepend>
                  <b-form-input
                    required
                    id="v-text"
                    v-model="item.text"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  />
                  <b-input-group-append is-text label-for="v-value">
                    Value
                  </b-input-group-append>
                  <b-form-input
                    required
                    id="v-value"
                    v-model="item.value"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  />
                  <b-input-group-append
                    @click="onRemoveSelection(index)"
                    is-text
                    label-for="v-close"
                    class="cursor-pointer"
                  >
                    <feather-icon
                      id="v-close"
                      icon="XIcon"
                      class="text-danger"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <b-button
              size="sm"
              @click="closeModal()"
              variant="danger"
              class="bg-darken-4 mr-1"
            >
              Cancel
            </b-button>
            <b-button
              :disabled="invalid"
              size="sm"
              @click="onSubmit()"
              variant="primary"
              class="bg-darken-4"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </section>
</template>

<script>
import statusesApi from '@api/statuses';
import { required } from '@validations';
import { queryParameters } from '@/schema';

export default {
  name: 'StatusesPage',
  components: {},
  data: () => ({
    required,
    modalShow: false,
    dataSource: [],
    totalCount: 0,
    page: 0,
    size: 0,
    selectionItems: [{ text: '', value: '' }],
    fields: [
      { caption: 'Name', field: 'name' },
      { caption: 'Description', field: 'description' },
      { caption: 'Code', field: 'code' },
    ],
    options: [
      { label: 'Company', value: 'coystatus' },
      { label: 'Service Request', value: 'sr:status' },
      { label: 'Customer Feedback:Priority', value: 'custfbprio' },
      { label: 'Customer Feedback:Status', value: 'custfbstatus' },
      { label: 'Training Status', value: 'trainingstatus' },
      {
        label: 'Training Management Status',
        value: 'trainingmanagement:status',
      },
      {
        label: 'Training Management Payment Status',
        value: 'trainingmanagement:payment',
      },
      { label: 'OT Claim Types', value: 'claims:ottypes' },
      { label: 'OT Claim Status', value: 'claims:status' },
      { label: 'OT Claim Categories', value: 'claims:categories' },
      { label: 'Contracts Status', value: 'contract:status' },
      {
        label: 'Relocation Item Master:Movement Status',
        value: 'movement:status',
      },
      {
        label: 'Relocation Item Master:Condition Status',
        value: 'condition:status',
      },
      {
        label: 'Renewal',
        value: 'renewal:status',
      },
      {
        label: 'Job Relocation:Movement Status',
        value: 'job_relocation:movement:status',
      },
      {
        label: 'Job Relocation:Condition Status',
        value: 'job_relocation:condition:status',
      },
    ],

    formData: {
      name: null,
      description: null,
      code: null,
      selections: [],
    },
  }),
  watch: {
    page(v) {
      this.loadSystemCode();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadSystemCode();
      }
    },
  },
  mounted() {
    this.loadSystemCode();
  },
  methods: {
    loadSystemCode() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      statusesApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;
          }

          this.totalCount = meta.page.total;
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const closeModalloadSystemCode = () => {
        this.loadSystemCode();
        this.closeModal();
        this.toastConfig();
      };

      this.$refs.statusRef.validate().then((success) => {
        let app;

        this.formData.selections = this.selectionItems;

        if (this.formData.id) {
          app = statusesApi.update(this.formData);
        } else {
          app = statusesApi.add(this.formData);
        }

        if (success) {
          if (app) {
            app
              .then(({ data }) => {
                closeModalloadSystemCode();
                this.selectionItems = [{ text: '', values: '' }];
              })
              .catch((errors) => {
                if (errors) {
                  const [err] = this.parseError(errors);
                  const { meta } = err;

                  if (meta) {
                    this.toastConfig(meta['Code.Unique'], 'error');
                  }
                }
              })
              .finally(() => {
                //
              });
          }
        }
      });
    },
    onOpenModal(event) {
      this.selectionItems = [{ text: '', value: '' }];
      this.modalShow = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    onEdit(data) {
      const { selections } = data;

      if (selections.length > 0) {
        this.selectionItems = selections;
      }

      this.$nextTick(() => {
        this.formData = Object.assign({}, data);
      });
    },
    onDelete(event) {
      statusesApi
        .delete(event)
        .then(() => {
          this.loadSystemCode();
          this.toastConfig('Delete successful');
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onAddSelection() {
      this.selectionItems.push({ text: '', value: '' });
    },
    onRemoveSelection(index) {
      this.selectionItems.splice(index, 1);

      if (this.selectionItems.length === 0) {
        this.onAddSelection();
      }
    },
    closeModal() {
      this.modalShow = false;
      const defaultForm = {
        name: null,
        description: null,
        code: null,
        selections: [],
      };

      this.$nextTick(() => {
        this.formData = Object.assign({}, defaultForm);
      });
    },
  },
};
</script>

<style></style>
